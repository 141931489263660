import { useEmblaCarousel } from 'embla-carousel/react'
import PropTypes from 'prop-types'
import first from 'lodash/first'
import React, { useCallback, useEffect, useState } from 'react'
import tw, { css } from 'twin.macro'
import { global } from '../styles/global'
import { StyleType } from '../utils/prop-types'
import Heading from './heading'
import Button from './button'
import Image from './image'
import { ReactComponent as CaretRight } from '../images/icon-caret-right.svg'

const NavButton = ({ enabled, onClick, reversed }) => (
  <button
    css={css`
      ${tw`flex items-center justify-center w-12 h-12 border-2`}
      ${reversed && tw`transform rotate-180`}
      svg {
        ${tw`transition transition-transform duration-300`}
        path {
          ${tw`text-opacity-100 fill-current text-primary-500`}
        }
      }
      &:hover {
        svg {
          ${tw`transform translate-x-1`}
        }
      }
      &:disabled {
        ${tw`pointer-events-none`}
        path {
          ${tw`text-opacity-40`}
        }
      }
    `}
    type="button"
    onClick={onClick}
    disabled={!enabled}
  >
    <CaretRight />
  </button>
)

NavButton.propTypes = {
  enabled: PropTypes.bool,
  onClick: PropTypes.func,
  reversed: PropTypes.bool,
}

const CapabilitiesCarousel = ({ capabilities, style }) => {
  const [viewportRef, embla] = useEmblaCarousel({ align: 'start' })
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [scrollSnaps, setScrollSnaps] = useState([])
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false)

  const scrollTo = useCallback((index) => embla && embla.scrollTo(index), [embla])
  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla])
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla])

  const onSelect = useCallback(() => {
    setSelectedIndex(embla.selectedScrollSnap())
    setPrevBtnEnabled(embla.canScrollPrev())
    setNextBtnEnabled(embla.canScrollNext())
  }, [embla, setSelectedIndex])

  useEffect(() => {
    if (!embla) return
    onSelect()
    setScrollSnaps(embla.scrollSnapList())
    embla.on('select', onSelect)
  }, [embla, onSelect])

  return (
    <>
      <div css={tw`flex flex-row items-center`}>
        <NavButton onClick={scrollPrev} enabled={prevBtnEnabled} reversed />
        <NavButton onClick={scrollNext} enabled={nextBtnEnabled} />
      </div>
      <div
        css={[
          css`
            ${tw`relative block overflow-visible`}
            &.is-draggable {
              ${tw`cursor-grab`}
            }
            &.is-dragging {
              ${tw`cursor-grabbing`}
            }
          `,
        ]}
        ref={viewportRef}
      >
        <div css={[tw`flex flex-row flex-nowrap`, style]}>
          {capabilities.map((t, i) => (
            <div
              key={i}
              css={[
                tw`flex flex-col px-4 py-4 items-center flex-none w-full mx-container-mobile`,
                i % 2 === 0 ? tw`bg-primary-200` : tw`text-white bg-primary-400`,
              ]}
            >
              <Heading content={t.titleNode} headingType="h4" style={tw`w-full`} />
              <div
                css={[
                  tw`flex flex-col items-start h-full overflow-hidden opacity-100 max-h-screen`,
                  selectedIndex !== i && tw`opacity-0 max-h-0`,
                ]}
              >
                <div css={tw`pt-5`}>
                  <Image image={t.image} />
                </div>
                <Heading headingType="h5" content={t.descriptionNode} style={tw`mt-6`} />
                <Button
                  type="primary"
                  size="sm"
                  theme="transparent-transparent"
                  label={first(t.callToAction).label}
                  link={first(t.callToAction).link}
                  style={[
                    css`
                      ${tw`mt-10 mb-20`}
                      span {
                        ${tw`ml-0`}
                      }
                    `,
                    i % 2 !== 0 &&
                      css`
                        span {
                          ${tw`text-white`}
                        }
                        svg {
                          ${tw`fill-white`}
                        }
                      `,
                  ]}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div
        css={[
          global`layout.container`,
          tw`flex flex-row items-center justify-center w-full mt-16 space-x-2`,
        ]}
      >
        {scrollSnaps.map((_, index) => (
          <button
            key={index}
            onClick={() => scrollTo(index)}
            css={[
              tw`w-24 h-0.5 bg-primary-500 opacity-30 transition-opacity duration-300 ease-in-out`,
              index === selectedIndex && tw`opacity-100`,
            ]}
          />
        ))}
      </div>
    </>
  )
}

CapabilitiesCarousel.propTypes = {
  capabilities: PropTypes.arrayOf(PropTypes.object),
  style: StyleType,
}

export default CapabilitiesCarousel
