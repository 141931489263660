import { useEmblaCarousel } from 'embla-carousel/react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import React, { useCallback, useEffect, useState } from 'react'
import tw, { css } from 'twin.macro'
import { StyleType } from '../utils/prop-types'
import Text from './text'
import Button from './button'
import Image from './image'
import NavButton from './nav-button'

const EcosystemSlider = ({ slides, style }) => {
  const [viewportRef, embla] = useEmblaCarousel({ align: 'start' })
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false)

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla])
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla])

  const onSelect = useCallback(() => {
    if (!embla) return
    setSelectedIndex(embla.selectedScrollSnap())
    setPrevBtnEnabled(embla.canScrollPrev())
    setNextBtnEnabled(embla.canScrollNext())
  }, [embla])

  useEffect(() => {
    if (!embla) return
    onSelect()
    embla.on('select', onSelect)
  }, [embla, onSelect])

  return (
    <>
      <div
        css={[
          css`
            ${tw`overflow-visible pb-12 lg:hidden`}
            &.is-draggable {
              ${tw`cursor-grab`}
            }
            &.is-dragging {
              ${tw`cursor-grabbing`}
            }
          `,
        ]}
        ref={viewportRef}
      >
        <div css={[tw`relative flex flex-row flex-nowrap`, style]}>
          {slides.map((t, i) => (
            <div
              key={i}
              css={[tw`flex flex-col py-0 items-center flex-none w-full mx-container-mobile`]}
            >
              <div
                css={[
                  tw`flex flex-col items-start h-full opacity-100 max-h-screen md:(max-h-screen)`,
                  selectedIndex !== i && tw`opacity-0 max-h-0`,
                ]}
              >
                <div css={tw`pt-5 h-4/5 md:(h-4/5)`}>
                  <Image
                    image={t?.image}
                    style={[
                      css`
                        object-position: center;
                      `,
                      tw`w-full`,
                    ]}
                  />
                </div>
                <Text content={t.titleNode} textType="section" style={tw`text-mobile-3xl mt-0`} />
                <Text content={t.descriptionNode} style={tw`mt-6`} />
                <Button
                  type="primary"
                  size="sm"
                  label={get(t, 'callToAction.label')}
                  link={get(t, 'callToAction.link')}
                  theme="transparent-transparent"
                  style={tw`mt-8 -ml-8`}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div
        css={tw`absolute top-13/100 mt-4 flex flex-row items-center w-full space-x-2 sm:top-3/25 md:(top-8/25) lg:hidden`}
      >
        <NavButton onClick={scrollPrev} enabled={prevBtnEnabled} reversed />
        <NavButton onClick={scrollNext} enabled={nextBtnEnabled} />
      </div>
    </>
  )
}

EcosystemSlider.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.object),
  style: StyleType,
}

export default EcosystemSlider
