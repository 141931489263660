import first from 'lodash/first'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import tw, { css } from 'twin.macro'
import { StyleType } from '../utils/prop-types'
import Button from './button'
import Heading from './heading'
import Image from './image'

const HorizontalAccordion = ({ tabs, style }) => {
  const [selected, setSelected] = useState(0)
  return (
    <div css={[tw`flex flex-col lg:(flex-row h-208)`, style]}>
      {tabs.map((tab, i) => (
        <div
          key={i}
          css={[
            tw`flex flex-col px-4 lg:(flex-row flex-auto)`,
            i % 2 === 0 ? tw`bg-primary-200 lg:mt-16` : tw`text-white bg-primary-400`,
          ]}
        >
          <div
            css={[
              tw`relative flex flex-row items-start flex-none py-5 lg:(flex-col items-center w-28 p-0)`,
              selected === i && tw`cursor-auto pointer-events-none`,
            ]}
            onClick={() => setSelected(i)}
            onKeyPress={() => setSelected(i)}
            role="button"
            tabIndex="0"
          >
            <Heading headingType="h4" content={`0${i + 1}`} style={tw`mr-4 lg:(mt-6 mr-0)`} />
            <Heading
              headingType="h4"
              content={tab.titleNode}
              style={[
                tw`lg:(absolute inset-x-0 inline-block transform -rotate-90 translate-y-1/2 whitespace-nowrap)`,
                i % 2 === 0 ? tw`lg:top-60` : tw`lg:top-108`,
              ]}
            />
          </div>
          <div
            css={[
              css`
                transition: max-width 0.3s ease, max-height 0.3s ease, opacity 0.3s ease;
              `,
              tw`flex flex-col items-start h-full overflow-hidden opacity-100 max-h-screen lg:(max-h-full max-w-screen-lg)`,
              selected !== i && tw`opacity-0 max-h-0 lg:(max-w-0 max-h-full)`,
            ]}
          >
            <div css={tw`pt-5 lg:(pt-10 pr-24)`}>
              <Image image={tab.image} />
            </div>
            <Heading
              headingType="h5"
              content={tab.descriptionNode}
              style={tw`mt-6 lg:(mt-10 pr-44)`}
            />
            <Button
              type="primary"
              size="sm"
              theme="transparent-transparent"
              label={first(tab.callToAction).label}
              link={first(tab.callToAction).link}
              style={[
                css`
                  ${tw`mt-10 mb-20 lg:(mt-12 mb-18)`}
                  span {
                    ${tw`ml-0`}
                  }
                `,
                i % 2 !== 0 &&
                  css`
                    span {
                      ${tw`text-white`}
                    }
                    svg {
                      ${tw`fill-white`}
                    }
                  `,
              ]}
            />
          </div>
        </div>
      ))}
    </div>
  )
}

HorizontalAccordion.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.object),
  style: StyleType,
}

export default HorizontalAccordion
