import PropTypes from 'prop-types'
import React from 'react'
import tw from 'twin.macro'
import { global } from '../styles/global'
import { HTMLType, ImageType, LinkType, StyleType } from '../utils/prop-types'
import Button from './button'
import Heading from './heading'
import Image from './image'
import Text from './text'

const ImageBanner = ({
  image,
  imageMobile,
  title,
  description,
  callToAction,
  buttonTheme,
  style,
}) => (
  <div
    css={[global`layout.container`, global`layout.grid`, tw`items-center py-16 lg:(py-24)`, style]}
  >
    <div css={tw`col-span-4 md:col-span-8 lg:col-span-5 xl:col-span-7`}>
      <Image image={image} imageMobile={imageMobile} style={tw`w-full`} />
    </div>
    <div
      css={tw`flex flex-col items-start col-span-4 mt-12
      md:col-span-8 lg:(col-start-7 col-end-13 mt-0) xl:(col-start-9 col-end-16)`}
    >
      <Heading content={title} headingType="h3" />
      <Text content={description} style={tw`mt-4`} />
      <Button
        type="primary"
        size="sm"
        theme={buttonTheme}
        label={callToAction.label}
        link={callToAction.link}
        disabled={callToAction.disabled}
        style={tw`mt-10`}
      />
    </div>
  </div>
)

ImageBanner.defaultProps = {
  buttonTheme: 'navy-cyan',
}

ImageBanner.propTypes = {
  image: ImageType,
  imageMobile: ImageType,
  title: HTMLType,
  description: HTMLType,
  callToAction: LinkType,
  buttonTheme: PropTypes.string,
  style: StyleType,
}

export default ImageBanner
