import React from 'react'
import tw, { css } from 'twin.macro'
import PropTypes from 'prop-types'
import { ReactComponent as CaretRight } from '../images/icon-caret-right.svg'

const NavButton = ({ enabled, onClick, reversed, style }) => (
  <button
    css={[
      css`
        ${tw`flex items-center justify-center w-12 h-12`}
        ${reversed && tw`transform rotate-180`}
        svg {
          ${tw`transition transition-transform duration-300`}
          path {
            ${tw`text-opacity-100 fill-current text-primary-500`}
          }
        }
        &:hover {
          svg {
            ${tw`transform translate-x-1`}
          }
        }
        &:disabled {
          ${tw`pointer-events-none`}
          path {
            ${tw`text-opacity-40`}
          }
        }
      `,
      style,
    ]}
    type="button"
    onClick={onClick}
    disabled={!enabled}
  >
    <CaretRight />
  </button>
)

NavButton.propTypes = {
  enabled: PropTypes.bool,
  onClick: PropTypes.func,
  reversed: PropTypes.bool,
}

export default NavButton
