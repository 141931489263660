import first from 'lodash/first'
import last from 'lodash/last'
import get from 'lodash/get'
import React from 'react'
import tw, { css } from 'twin.macro'
import Image from './image'
import Heading from './heading'
import Button from './button'
import Text from './text'
import { global } from '../styles/global'

const EcosystemAnimation = ({ image, firstLine, secondLine, thirdLine, layers, style }) => {
  return (
    <div id="ecosystems" css={[global`layout.platform-grid`, tw`hidden lg:(w-full grid-rows-6)`]}>
      <div
        css={tw`flex flex-col lg:(row-start-1 row-end-13 col-start-4 col-end-8) xl:(row-end-17 col-start-4 col-end-14)`}
      >
        <Image image={image} style={tw`w-full h-full`} />
      </div>
      <div
        css={tw`relative flex flex-col lg:(row-start-1 row-end-4 col-start-1 col-end-4) xl:(mt-20 row-end-6 col-start-1 col-end-4) xxl:(mt-24)`}
      >
        <Heading
          headingType="h4"
          content={first(layers).titleNode}
          style={css`
            ${tw`text-primary-500 font-bold`}
            strong {
              ${tw`font-medium`}
            }
          `}
        />
        <Text
          content={first(layers).descriptionNode}
          style={[global`typography.body-md`, tw`mt-7 font-normal`]}
        />
        <Button
          type="primary"
          size="sm"
          label={get(first(layers), 'callToAction.label')}
          link={get(first(layers), 'callToAction.link')}
          theme="transparent-transparent"
          style={tw`mt-8 -ml-5`}
        />
        <Image
          image={firstLine}
          style={tw`h-full relative lg:(w-3/5 left-64 top-32 rotate-12) xl:(w-4/5 left-64 -top-48)`}
        />
      </div>
      <div
        css={tw`relative flex flex-col mt-8 lg:(mt-0 row-start-8 row-end-13 col-start-9 col-end-13) xl:(row-start-12 row-end-17 col-start-14 col-end-17)`}
      >
        <Heading
          headingType="h4"
          content={last(layers).titleNode}
          style={css`
            ${tw`text-primary-500 font-bold`}
            strong {
              ${tw`font-medium`}
            }
          `}
        />
        <Text
          content={last(layers).descriptionNode}
          style={[global`typography.body-md`, tw`mt-7 font-normal`]}
        />
        <Button
          type="primary"
          size="sm"
          label={get(last(layers), 'callToAction.label')}
          link={get(last(layers), 'callToAction.link')}
          theme="transparent-transparent"
          style={tw`mt-8 -ml-5`}
        />
        <Image
          image={thirdLine}
          style={tw`h-full lg:(w-3/5 relative -top-116 -left-48 transform rotate-62) xl:(w-full relative -top-88 -left-56 -rotate-6) xxl:(relative -top-72 -left-72)`}
        />
      </div>
      <div
        css={tw`relative flex flex-col mt-8 lg:(mt-0 row-start-6 row-end-12 col-start-1 col-end-4) xl:(row-start-10 row-end-17 col-start-1 col-end-4)`}
      >
        <Heading
          headingType="h4"
          content={first(layers.slice(1, 2)).titleNode}
          style={css`
            ${tw`text-primary-500 font-bold`}
            strong {
              ${tw`font-medium`}
            }
          `}
        />
        <Text
          content={first(layers.slice(1, 2)).descriptionNode}
          style={[global`typography.body-md`, tw`mt-7 font-normal`]}
        />
        <Button
          type="primary"
          size="sm"
          label={get(first(layers.slice(1, 2)), 'callToAction.label')}
          link={get(first(layers.slice(1, 2)), 'callToAction.link')}
          theme="transparent-transparent"
          style={tw`mt-8 -ml-5`}
        />
        <Image
          image={secondLine}
          style={tw`relative h-full lg:(w-4/5 -top-112 left-40) xl:(w-4/5 -top-108 left-65) xxl: (w-3/5 -top-84 left-84)`}
        />
      </div>
    </div>
  )
}

export default EcosystemAnimation
